:root {
  --haal-blue: #195EA4;
}
* {
  font-family: 'Montserrat', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}
li{
  list-style: none;
  margin: 0px;
}
ul{
  margin: 0px;
}
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.haal-respond-situation-pending{background-color: rgb(248 113 113)}
.haal-respond-situation-taken-charge{background-color: rgb(74 222 128)}
.haal-respond-situation-monitoring{background-color: rgb(250 204 21)}
.haal-respond-situation-solved{background-color: #a5be00;}

.haal-error-section{
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.login-bg{
  background: rgb(25,94,164);
background: linear-gradient(180deg, rgba(25,94,164,1) 0%, rgba(116,116,242,1) 35%, rgba(0,212,255,1) 100%);
}
.side-bar-open{border-radius:0px 0px 15px 0px}
.side-bar-open-width{width: 20%;}
.side-bar-close-width{width: 70px;}
.sidebar-link{
  min-height: 25px;
}
.padding-for-sidebar-open{padding-left:20%}
.padding-for-sidebar-close{padding-left:70px}


/* TEXT */
.haal-icon-font-size{font-size: 16px;}
.haal-title{
  font-weight:bold;
  font-size:1rem;
  line-height:1rem
}
.haal-big-title{
  font-weight:bold;
  font-size:2rem;
  line-height:2rem
}
.haal-subtitle{
  /* font-weight:bold; */
  font-size:0.9rem;
  line-height:0.9rem;

}
.haal-text-color-primary{color: rgb(25,94,164);}
.haal-text{font-size:0.8rem;line-height:0.8rem}
.haal-text-small {font-size: 0.7rem;line-height: 0.7rem;}
.haal-text-underline{text-decoration:underline;text-decoration-color: #b3b3b3;cursor:pointer;}
.haal-y-bg{
  background-color: #f7be4d;
}

.heart-icon-color{
  color: #fcc124;
}
.heart-icon-color{
  color: #ff595e;
}
.haal-b-text{
  color: var(--haal-blue);
}
.haal-b-bg{
  background-color: var(--haal-blue);
}
.haal-danger-bg{
  background: #cc3232 !important
}
.haal-danger-text{
  color: #cc3232 !important
}
/* SIDEBAR */
.sidebar-wrapper{
  border-right: 1px solid #b3b3b3;
}
.sidebar-icon-wrapper{
  border-top: 2px solid white;
}
.sidebar-icon-wrapper ul li {
  /* margin: 15px 0; */
}
.haal-table{border:1px solid #dcdcdc !important;border-radius: 15px;}
.haal-pending-situations-table-situation-label{font-size: 10px;font-weight: bold;}
.haal-pending-situations-table-situation-common-circle{width: 20px;height: 20px;background: #dcdcdc;margin: auto;display: flex;border-radius: 100%;margin-top: 7px;}
.haal-pending-situations-table-situation-circle-green{background-color: #2dc937;}
.haal-pending-situations-table-situation-circle-red{background-color: #cc3232;}
/* legenda */
.haal-client-status-label{font-size: 12px;font-weight: bold;line-height: 15px;margin-left: 10px;}
.haal-client-status-circle-wrapper{min-width: 50px;display: flex;justify-content: center;}
.haal-client-status-common-circle{width: 30px;height: 30px;background: #dcdcdc;display: flex;border-radius: 100%;}
.haal-client-status-circle-green{background-color: #2dc937;}
.haal-client-status-circle-red{background-color: #cc3232;}
.haal-client-status-circle-orange{background-color: #e7b416;}
.haal-client-status-circle-blue{background-color: #1e34f8;}
.haal-client-status-circle-grey{background-color: #bfbdbd;}
.haal-client-status-circle-black{background-color: #000000;}
.haal-client-status-circle-size-big{width: 50px !important;height: 50px !important;}
.haal-client-status-circle-size-medium{width: 30px !important;height: 30px !important;}
.haal-client-status-circle-size-small{width: 15px !important;height: 15px !important;}
.haal-expansion-panel{border: 1px solid #dcdcdc !important;border-radius: 15px !important;}
.patient-card{
  border: 1px solid #b3b3b3;
}
.icon-wrapper{
  border-radius: 100%;
  /* width: 30px;
  height: 30px; */
  justify-content: center;
  display: flex;
  align-items: center;
}
.haal-overlay-container{position: fixed;display: flex;justify-content: end;bottom: 1.25rem;right: 1.25rem;z-index: 999;}

.haal-list-decorator{display: block;width: 2px;background: #b3b3b3;}
.notes-list{max-height: 40vh;overflow-y: hidden;}
.notes-list-expanded{max-height: unset;transition: max-height 0.5s ease-in;}
.notes-overlay{position: absolute;bottom: 0;height: 15vh;width: 100%;background: rgb(255,255,255);background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 83%) 80%, rgb(255 255 255) 100%);}


.hall-situation-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  height: 400px;
  width:684px;
  margin: 0 auto;
}
.cerntral-circle {
  background: #2e2e2e;
  height: 150px;
  width: 150px;
  border-radius: 100%;
  left: calc(50% - 75px);
  top: calc(50% - 75px);
  z-index: 4; 
}
.hall-client-profile-image-container{border: 2px solid var(--haal-blue);}
.hall-client-profile-image{border-radius: 100%;}
/* .physical-status-circle{ background: #2dc937; height: 100px; width: 100px; border-radius: 100%; z-index: 4; }
.sleep-activity-status-circle{ background: #bfbdbd; height: 150px; width: 150px; border-radius: 100%; right: 0;z-index: 4;}
.cognitive-status-circle{ background: #e7b416; height: 150px; width: 150px; border-radius: 100%; bottom: 0;z-index: 4;}
.general-well-being-status-circle{ background: #cc3232; height: 200px; width: 200px; border-radius: 100%; right: 0; bottom: 0;z-index: 4;} */
.status-circle-content{display: flex;
  /* justify-content: space-between; */
  width: 80%;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center; 
  font-weight:bold;
  font-size:1rem;
  line-height:1rem}
.hall-situation-diagonal-container{display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  z-index: 3;
  position: absolute;
  width: 100%;
}
.hall-situation-diagonal{height:1px;background: black; width:100%;}
.hall-situation-diagonal-1{transform: rotate(27deg)}
.hall-situation-diagonal-2{transform: rotate(-27deg)}
.haal-situation-table-border-radius-top{border-top-left-radius: 15px;border-top-right-radius: 15px;}
.haal-situation-table-border-radius-bottom{border-bottom-left-radius: 15px;border-bottom-right-radius: 15px;}
.hall-table-cell-icon{width: 1.5rem;}
.haal-notification-item{background: #f1f1f1;}
.haal-notification-item:hover{background: #d3d3d36b;}
.haal-notification-item-active{background: var(--haal-blue) !important; color: white}
.haal-fake-input-label-container{
  border: 1px solid #b4b4b4e0;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16.5px 14px;
}
.active-avatar{background: #f7be4d}
.haal-delete-button{background: #cc3232 !important;color: white !important;font-weight: bold !important;}
.haal-phone-number-style{border-radius: 4px;border: 1px solid #bebebe;padding: 14px;}
.haal-phone-number-style *:focus-visible{border:none !important;}

.menu-links{
  color: white;
}
.menu-links svg{
  color: white;
}

/* .sidebar-not{
  position: fixed;
  top: 55px;
  margin-right: 200px;
  padding-left: 1.25rem;
  padding-right: 80px;
} */
.sidebar-menu{
  position: fixed;
  bottom: 0;
  margin-left: 0px;
  padding-left: 40px;
  padding-right: 100px;
}
.scroll{
  scroll-behavior: smooth;
  overflow-y: auto;    
  min-height: 70px;
  max-height: 500px;
}

.spinner-color{
  color: grey;
  text-align: center;
}

.client-status{
  /* background: #195EA4; */
  color: white;
  padding: 8px 15px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.red-decorator{
  width: 10px;
  background: #c00000;
  margin: 0 0.5rem 0 0;
}
.archived-device-circle-wrapper{top:0px;right: -10px;}
.archived-device-circle{height: 20px;width: 20px;background-color: #b1b1b1;border-radius: 100%;display: flex;justify-content: center;}
.activity-graph-card-selected{border-color: #3d86d0;box-shadow: 0 0 17px black;animation: pulsate 1s  ease-out infinite;}
@keyframes pulsate {
  0%   { box-shadow: 0 0 0 #195EA4; }
  50%  { box-shadow: 0 0 17px #195EA4; }
  100% { box-shadow: 0 0 0 #3d86d0; }
}

.kompy .leaflet-container {
  width: 100%;
  height: 40rem;
  z-index: 1;
}

.kompy .leaflet-container img {
  display: initial;
}